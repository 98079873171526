import * as tslib_1 from "tslib";
import { setPrototypeOf } from './polyfill';
/** An error emitted by Sentry SDKs and related utilities. */
var SentryError = /** @class */function (_super) {
  tslib_1.__extends(SentryError, _super);
  function SentryError(message) {
    var _newTarget = this.constructor;
    var _this = _super.call(this, message) || this;
    _this.message = message;
    // tslint:disable:no-unsafe-any
    _this.name = _newTarget.prototype.constructor.name;
    setPrototypeOf(_this, _newTarget.prototype);
    return _this;
  }
  return SentryError;
}(Error);
export { SentryError };
