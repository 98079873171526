import { logger, SentryError } from '@sentry/utils';
import { NoopTransport } from './transports/noop';
/**
 * This is the base implemention of a Backend.
 * @hidden
 */
var BaseBackend = /** @class */function () {
  /** Creates a new backend instance. */
  function BaseBackend(options) {
    this._options = options;
    if (!this._options.dsn) {
      logger.warn('No DSN provided, backend will not do anything.');
    }
    this._transport = this._setupTransport();
  }
  /**
   * Sets up the transport so it can be used later to send requests.
   */
  BaseBackend.prototype._setupTransport = function () {
    return new NoopTransport();
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.eventFromException = function (_exception, _hint) {
    throw new SentryError('Backend has to implement `eventFromException` method');
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.eventFromMessage = function (_message, _level, _hint) {
    throw new SentryError('Backend has to implement `eventFromMessage` method');
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.sendEvent = function (event) {
    this._transport.sendEvent(event).catch(function (reason) {
      logger.error("Error while sending event: " + reason);
    });
  };
  /**
   * @inheritDoc
   */
  BaseBackend.prototype.getTransport = function () {
    return this._transport;
  };
  return BaseBackend;
}();
export { BaseBackend };
