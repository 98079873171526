import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';

import { reducerToken, reducerProvider } from '@app/store';
import { AuthEffects } from '@app/store/auth';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TestsEffects } from '@app/store/tests';
import { PensEffects } from '@app/store/pens';
import { TraitsEffects } from '@app/store/traits';
import { AnimalsEffects } from '@app/store/animals';
import { RouterModule } from '@angular/router';

@NgModule({ imports: [RouterModule.forRoot([]),
        StoreModule.forRoot(reducerToken, {
            runtimeChecks: {
                strictActionImmutability: false,
                strictStateImmutability: false
            },
            // metaReducers: [logger]
        }),
        EffectsModule.forRoot([AuthEffects, TestsEffects, TraitsEffects, AnimalsEffects, PensEffects]),
        StoreDevtoolsModule.instrument()], providers: [
        reducerProvider,
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppStoreModule {
}

function logger(reducer: any) {
    return function(state: any, action: any) {
        console.groupCollapsed('Action: ' + action.type);
        console.log('State: ', state);
        console.log('Action: ', action);
        console.groupEnd();

        return reducer(state, action);
    };
}
