import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { AbstractApiService } from '@app/services/abstract-api.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PenApiService extends AbstractApiService {

    private PEN_URL = '/pen_identities/';
    constructor(public http: HttpClient,
                protected store: Store<AppState>) {
        super(store, http);
    }

    public getPenIdentities(blnNumber): any {
        return this.get(this.PEN_URL + blnNumber).pipe(
            map((res) => {
                if (res && res.items) {
                    if (res.success === 'true') {
                        return res.items;
                    }
                    else {
                        throw throwError(res.success);
                    }
                }
            }),
            catchError((error) => {
                throw error;
            })
        );
    }
}
