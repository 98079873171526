Object.defineProperty(exports, "__esModule", {
  value: true
});
var types_1 = require("@sentry/types");
exports.Severity = types_1.Severity;
exports.Status = types_1.Status;
var core_1 = require("@sentry/core");
exports.addGlobalEventProcessor = core_1.addGlobalEventProcessor;
exports.addBreadcrumb = core_1.addBreadcrumb;
exports.captureException = core_1.captureException;
exports.captureEvent = core_1.captureEvent;
exports.captureMessage = core_1.captureMessage;
exports.configureScope = core_1.configureScope;
exports.withScope = core_1.withScope;
exports.getHubFromCarrier = core_1.getHubFromCarrier;
exports.getCurrentHub = core_1.getCurrentHub;
exports.Hub = core_1.Hub;
exports.Scope = core_1.Scope;
var browser_1 = require("@sentry/browser");
exports.BrowserIntegrations = browser_1.Integrations;
var backend_1 = require("./backend");
exports.CordovaBackend = backend_1.CordovaBackend;
var client_1 = require("./client");
exports.CordovaClient = client_1.CordovaClient;
var sdk_1 = require("./sdk");
exports.init = sdk_1.init;
exports.setDist = sdk_1.setDist;
exports.setRelease = sdk_1.setRelease;
var version_1 = require("./version");
exports.SDK_NAME = version_1.SDK_NAME;
exports.SDK_VERSION = version_1.SDK_VERSION;
var Integrations = require("./integrations");
exports.Integrations = Integrations;
