import { fill, getGlobalObject } from '@sentry/utils';
import { wrap } from '../helpers';
/** Wrap timer functions and event targets to catch errors and provide better meta data */
var TryCatch = /** @class */function () {
  function TryCatch() {
    /** JSDoc */
    this._ignoreOnError = 0;
    /**
     * @inheritDoc
     */
    this.name = TryCatch.id;
  }
  /** JSDoc */
  TryCatch.prototype._wrapTimeFunction = function (original) {
    return function () {
      var args = [];
      for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
      }
      var originalCallback = args[0];
      args[0] = wrap(originalCallback, {
        mechanism: {
          data: {
            function: getFunctionName(original)
          },
          handled: true,
          type: 'instrument'
        }
      });
      return original.apply(this, args);
    };
  };
  /** JSDoc */
  TryCatch.prototype._wrapRAF = function (original) {
    return function (callback) {
      return original(wrap(callback, {
        mechanism: {
          data: {
            function: 'requestAnimationFrame',
            handler: getFunctionName(original)
          },
          handled: true,
          type: 'instrument'
        }
      }));
    };
  };
  /** JSDoc */
  TryCatch.prototype._wrapEventTarget = function (target) {
    var global = getGlobalObject();
    var proto = global[target] && global[target].prototype;
    if (!proto || !proto.hasOwnProperty || !proto.hasOwnProperty('addEventListener')) {
      return;
    }
    fill(proto, 'addEventListener', function (original) {
      return function (eventName, fn, options) {
        try {
          // tslint:disable-next-line:no-unbound-method strict-type-predicates
          if (typeof fn.handleEvent === 'function') {
            fn.handleEvent = wrap(fn.handleEvent.bind(fn), {
              mechanism: {
                data: {
                  function: 'handleEvent',
                  handler: getFunctionName(fn),
                  target: target
                },
                handled: true,
                type: 'instrument'
              }
            });
          }
        } catch (err) {
          // can sometimes get 'Permission denied to access property "handle Event'
        }
        return original.call(this, eventName, wrap(fn, {
          mechanism: {
            data: {
              function: 'addEventListener',
              handler: getFunctionName(fn),
              target: target
            },
            handled: true,
            type: 'instrument'
          }
        }), options);
      };
    });
    fill(proto, 'removeEventListener', function (original) {
      return function (eventName, fn, options) {
        var callback = fn;
        try {
          callback = callback && (callback.__sentry_wrapped__ || callback);
        } catch (e) {
          // ignore, accessing __sentry_wrapped__ will throw in some Selenium environments
        }
        return original.call(this, eventName, callback, options);
      };
    });
  };
  /**
   * Wrap timer functions and event targets to catch errors
   * and provide better metadata.
   */
  TryCatch.prototype.setupOnce = function () {
    this._ignoreOnError = this._ignoreOnError;
    var global = getGlobalObject();
    fill(global, 'setTimeout', this._wrapTimeFunction.bind(this));
    fill(global, 'setInterval', this._wrapTimeFunction.bind(this));
    fill(global, 'requestAnimationFrame', this._wrapRAF.bind(this));
    ['EventTarget', 'Window', 'Node', 'ApplicationCache', 'AudioTrackList', 'ChannelMergerNode', 'CryptoOperation', 'EventSource', 'FileReader', 'HTMLUnknownElement', 'IDBDatabase', 'IDBRequest', 'IDBTransaction', 'KeyOperation', 'MediaController', 'MessagePort', 'ModalWindow', 'Notification', 'SVGElementInstance', 'Screen', 'TextTrack', 'TextTrackCue', 'TextTrackList', 'WebSocket', 'WebSocketWorker', 'Worker', 'XMLHttpRequest', 'XMLHttpRequestEventTarget', 'XMLHttpRequestUpload'].forEach(this._wrapEventTarget.bind(this));
  };
  /**
   * @inheritDoc
   */
  TryCatch.id = 'TryCatch';
  return TryCatch;
}();
export { TryCatch };
/**
 * Safely extract function name from itself
 */
function getFunctionName(fn) {
  try {
    return fn && fn.name || '<anonymous>';
  } catch (e) {
    // Just accessing custom props in some Selenium environments
    // can cause a "Permission denied" exception (see raven-js#495).
    return '<anonymous>';
  }
}
