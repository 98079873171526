import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
    },
    {
        path: 'login',
        loadChildren: () => import('@app/pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('@app/pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
    },
    {
        path: 'atv-test',
        loadChildren: () => import('@app/pages/atv-test/atv-test.module').then(m => m.AtvTestPageModule)
    }, {
        path: 'tva-test',
        loadChildren: () => import('@app/pages/tva-test/tva-test.module').then(m => m.TvaTestPageModule)
    },
    {
        path: 'recorded-test',
        loadChildren: () => import('@app/pages/recorded-tests/recorded-tests.module').then(m => m.RecordedTestsPageModule)
    },
    {
        path: 'test-settings',
        loadChildren: () => import('@app/pages/test-settings/test-settings.module').then(m => m.TestSettingsPageModule)
    },
    {
        path: 'download-animals',
        loadChildren: () => import('@app/pages/download-animals/download-animals.module').then(m => m.DownloadAnimalsPageModule)
    },
    {
        path: 'settings',
        loadChildren: () => import('@app/pages/settings/settings.module').then(m => m.SettingsPageModule)
    }
];

// const routes = [
//     { path: 'dashboard', loadChildren: '@app/pages/dashboard/dashboard.module#DashboardPageModule', pathMatch: 'prefix' },
//     { path: 'login', loadChildren: '@app/pages/login/login.module#LoginPageModule', pathMatch: 'prefix' },
//     { path: 'atv-test', loadChildren: '@app/pages/atv-test/atv-test.module#AtvTestPageModule', pathMatch: 'prefix' },
//     { path: 'tva-test', loadChildren: '@app/pages/tva-test/tva-test.module#TvaTestPageModule', pathMatch: 'prefix' },
//     { path: 'recorded-test', loadChildren: '@app/pages/recorded-tests/recorded-tests.module#RecordedTestsPageModule', pathMatch: 'prefix' },
//     { path: 'test-settings', loadChildren: '@app/pages/test-settings/test-settings.module#TestSettingsPageModule', pathMatch: 'prefix' },
//     { path: 'download-animals', loadChildren: '@app/pages/download-animals/download-animals.module#DownloadAnimalsPageModule', pathMatch: 'prefix' },
//     { path: 'settings', loadChildren: '@app/pages/settings/settings.module#SettingsPageModule', pathMatch: 'prefix' },
// ];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
