import { keysToEventMessage, normalizeToSize } from '@sentry/utils';
import { _computeStackTrace } from './tracekit';
var STACKTRACE_LIMIT = 50;
/**
 * This function creates an exception from an TraceKitStackTrace
 * @param stacktrace TraceKitStackTrace that will be converted to an exception
 * @hidden
 */
export function exceptionFromStacktrace(stacktrace) {
  var frames = prepareFramesForEvent(stacktrace.stack);
  var exception = {
    type: stacktrace.name,
    value: stacktrace.message
  };
  if (frames && frames.length) {
    exception.stacktrace = {
      frames: frames
    };
  }
  // tslint:disable-next-line:strict-type-predicates
  if (exception.type === undefined && exception.value === '') {
    exception.value = 'Unrecoverable error caught';
  }
  return exception;
}
/**
 * @hidden
 */
export function eventFromPlainObject(exception, syntheticException) {
  var exceptionKeys = Object.keys(exception).sort();
  var event = {
    extra: {
      __serialized__: normalizeToSize(exception)
    },
    message: "Non-Error exception captured with keys: " + keysToEventMessage(exceptionKeys)
  };
  if (syntheticException) {
    var stacktrace = _computeStackTrace(syntheticException);
    var frames_1 = prepareFramesForEvent(stacktrace.stack);
    event.stacktrace = {
      frames: frames_1
    };
  }
  return event;
}
/**
 * @hidden
 */
export function eventFromStacktrace(stacktrace) {
  var exception = exceptionFromStacktrace(stacktrace);
  return {
    exception: {
      values: [exception]
    }
  };
}
/**
 * @hidden
 */
export function prepareFramesForEvent(stack) {
  if (!stack || !stack.length) {
    return [];
  }
  var localStack = stack;
  var firstFrameFunction = localStack[0].func || '';
  var lastFrameFunction = localStack[localStack.length - 1].func || '';
  // If stack starts with one of our API calls, remove it (starts, meaning it's the top of the stack - aka last call)
  if (firstFrameFunction.includes('captureMessage') || firstFrameFunction.includes('captureException')) {
    localStack = localStack.slice(1);
  }
  // If stack ends with one of our internal API calls, remove it (ends, meaning it's the bottom of the stack - aka top-most call)
  if (lastFrameFunction.includes('sentryWrapped')) {
    localStack = localStack.slice(0, -1);
  }
  // The frame where the crash happened, should be the last entry in the array
  return localStack.map(function (frame) {
    return {
      colno: frame.column,
      filename: frame.url || localStack[0].url,
      function: frame.func || '?',
      in_app: true,
      lineno: frame.line
    };
  }).slice(0, STACKTRACE_LIMIT).reverse();
}
