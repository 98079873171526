import { Injectable } from '@angular/core'
import { AppState } from '@app/store'
import { Store } from '@ngrx/store'
import { BehaviorSubject, forkJoin, from, Observable, of } from 'rxjs'
import { catchError, timeout } from 'rxjs/operators'
import { AuthService } from '@app/services/auth/auth.service';
import { map } from 'rxjs/operators';
import { authActions } from '@app/store/auth';

/**
 * This service will initialize any other service that implements the IAppInitOptions interface.
 * Services can be added simply by adding them to the array that registerServices is called with.
 * The init function specified in IAppInitOptions will be called.
 * If awaitServices are specified in IAppInitOptions, the service will wait for these to complete first.
 * The NgRx store can be subscribed to for updates so that other effects can be triggered based on the status.
 * Notably, the first view can be displayed to the user after all services have been initialized.
 */
@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    constructor(private _store: Store<AppState>,
                private authService: AuthService) {}

    // Initialize provided when the app launches
    public init = async() => {
        // check if a user is logged in
        const username = await this.authService.getLoggedUser()

        if (username) {
            const user = await this.authService.getUser(username)
            this._store.dispatch(authActions.alreadyLoggedIn({ user }));
        }
    }
}
