import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppStoreModule } from '@app/store/store.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MenuComponent } from '@app/components/menu/menu.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DatePipe } from '@angular/common';
import { RefreshSessionInterceptor } from '@app/services/interceptor/refresh-token.interceptor';
import * as Sentry from 'sentry-cordova';
import { SentryIonicErrorHandler } from '@app/services/error-handling/errorHandler.sentry';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import {
    TRANSLOCO_LOADER,
    TRANSLOCO_CONFIG,
    TranslocoConfig,
    TranslocoModule, Translation, TranslocoLoader
} from '@ngneat/transloco';
import {provideIonicAngular} from "@ionic/angular/standalone";

// For www/browser version Production: tono, Test: tetono
export function translateHttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/tono/assets/i18n/');
}

Sentry.init({ dsn: 'https://0e437d216e514b15b97c9aae31a70136@o553336.ingest.sentry.io/5680418' });

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(private http: HttpClient) {}

    getTranslation(lang: string) {
        return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
    }
}

@NgModule({
    declarations: [AppComponent, MenuComponent],
    imports: [
        FormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        IonicModule.forRoot({ animated: false }),
        AppRoutingModule,
        AppStoreModule,
        IonicStorageModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
        TranslocoModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: RefreshSessionInterceptor, multi: true },
        { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
        {
            provide: TRANSLOCO_CONFIG,
            useValue: {
                availableLangs: ['de', 'en', 'es', 'fr', 'nl', 'no', 'pt'],
                reRenderOnLangChange: true,
                fallbackLang: 'en',
                defaultLang: 'en'
            } as TranslocoConfig
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
        DatePipe,
        provideIonicAngular(),
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
