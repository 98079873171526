import { createReducer, on } from '@ngrx/store'
import * as actions from './platform.actions'
import { IPlatformState, platformInitialState } from './platform.state'

export { IPlatformState as PlatformState }

export const PlatformReducer = createReducer(
    platformInitialState,

    on(actions.setPlatform, (state, { platformTypes }) => ({ ...state, ...platformTypes })),
    on(actions.setNetwork, (state, { isConnected, connectionType }) => ({ ...state, isConnected, connectionType }))
)
