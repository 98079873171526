import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { pensActions } from '@app/store/pens/pens.actions';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { PenApiService } from '@app/store/services/pen-api.service';
import { PenService } from '@app/services/pens/pen.service';

@Injectable()
export class PensEffects {

    getPen$ = createEffect(
        () => this.actions.pipe(
            ofType(pensActions.getPens),
            mergeMap((action: any) => {
                return from(this.penApiService.getPenIdentities(action.blnNumber)).pipe(
                    map((pens: any) => {
                        this.penService.setFetchPensDate();
                        this.penService.setPenList(action.blnNumber, pens);
                        return pensActions.setPens({ pens });
                    }),
                    catchError((err) => {
                        let errorMessage = err;
                        if (err.statusText === 'Unknown Error') {
                            errorMessage = 'The server is offline, try it again later.';
                        }
                        if (err) {
                            errorMessage = 'The username or password is incorrect';
                        }
                        return of(pensActions.setTestsError({ errorMessage, blnNumber: action.blnNumber }));
                    })
                );
            })
        )
    );

    getLocalPens$ = createEffect(
        () => this.actions.pipe(
            ofType(pensActions.getLocalPens),
            mergeMap(() => {
                return from(this.penService.getPensList(0)).pipe(
                    map((pens: any) => {
                        return pensActions.setLocalPens({ pens });
                    }),
                );
            })
        )
    );

    constructor(private penApiService: PenApiService,
                private penService: PenService,
                private actions: Actions) {
    }
}
