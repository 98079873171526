import * as tslib_1 from "tslib";
import { captureException, getCurrentHub, withScope } from '@sentry/core';
import { addExceptionTypeValue, isString, normalize } from '@sentry/utils';
var debounceDuration = 1000;
var keypressTimeout;
var lastCapturedEvent;
var ignoreOnError = 0;
/**
 * @hidden
 */
export function shouldIgnoreOnError() {
  return ignoreOnError > 0;
}
/**
 * @hidden
 */
export function ignoreNextOnError() {
  // onerror should trigger before setTimeout
  ignoreOnError += 1;
  setTimeout(function () {
    ignoreOnError -= 1;
  });
}
/**
 * Instruments the given function and sends an event to Sentry every time the
 * function throws an exception.
 *
 * @param fn A function to wrap.
 * @returns The wrapped function.
 * @hidden
 */
export function wrap(fn, options, before) {
  if (options === void 0) {
    options = {};
  }
  // tslint:disable-next-line:strict-type-predicates
  if (typeof fn !== 'function') {
    return fn;
  }
  try {
    // We don't wanna wrap it twice
    if (fn.__sentry__) {
      return fn;
    }
    // If this has already been wrapped in the past, return that wrapped function
    if (fn.__sentry_wrapped__) {
      return fn.__sentry_wrapped__;
    }
  } catch (e) {
    // Just accessing custom props in some Selenium environments
    // can cause a "Permission denied" exception (see raven-js#495).
    // Bail on wrapping and return the function as-is (defers to window.onerror).
    return fn;
  }
  var sentryWrapped = function () {
    // tslint:disable-next-line:strict-type-predicates
    if (before && typeof before === 'function') {
      before.apply(this, arguments);
    }
    var args = Array.prototype.slice.call(arguments);
    // tslint:disable:no-unsafe-any
    try {
      var wrappedArguments = args.map(function (arg) {
        return wrap(arg, options);
      });
      if (fn.handleEvent) {
        // Attempt to invoke user-land function
        // NOTE: If you are a Sentry user, and you are seeing this stack frame, it
        //       means the sentry.javascript SDK caught an error invoking your application code. This
        //       is expected behavior and NOT indicative of a bug with sentry.javascript.
        return fn.handleEvent.apply(this, wrappedArguments);
      }
      // Attempt to invoke user-land function
      // NOTE: If you are a Sentry user, and you are seeing this stack frame, it
      //       means the sentry.javascript SDK caught an error invoking your application code. This
      //       is expected behavior and NOT indicative of a bug with sentry.javascript.
      return fn.apply(this, wrappedArguments);
      // tslint:enable:no-unsafe-any
    } catch (ex) {
      ignoreNextOnError();
      withScope(function (scope) {
        scope.addEventProcessor(function (event) {
          var processedEvent = tslib_1.__assign({}, event);
          if (options.mechanism) {
            addExceptionTypeValue(processedEvent, undefined, undefined, options.mechanism);
          }
          processedEvent.extra = tslib_1.__assign({}, processedEvent.extra, {
            arguments: normalize(args, 3)
          });
          return processedEvent;
        });
        captureException(ex);
      });
      throw ex;
    }
  };
  // Accessing some objects may throw
  // ref: https://github.com/getsentry/sentry-javascript/issues/1168
  try {
    for (var property in fn) {
      if (Object.prototype.hasOwnProperty.call(fn, property)) {
        sentryWrapped[property] = fn[property];
      }
    }
  } catch (_oO) {} // tslint:disable-line:no-empty
  fn.prototype = fn.prototype || {};
  sentryWrapped.prototype = fn.prototype;
  Object.defineProperty(fn, '__sentry_wrapped__', {
    enumerable: false,
    value: sentryWrapped
  });
  // Signal that this function has been wrapped/filled already
  // for both debugging and to prevent it to being wrapped/filled twice
  Object.defineProperties(sentryWrapped, {
    __sentry__: {
      enumerable: false,
      value: true
    },
    __sentry_original__: {
      enumerable: false,
      value: fn
    }
  });
  // Restore original function name (not all browsers allow that)
  try {
    var descriptor = Object.getOwnPropertyDescriptor(sentryWrapped, 'name');
    if (descriptor.configurable) {
      Object.defineProperty(sentryWrapped, 'name', {
        get: function () {
          return fn.name;
        }
      });
    }
  } catch (_oO) {
    /*no-empty*/
  }
  return sentryWrapped;
}
var debounceTimer = 0;
/**
 * Wraps addEventListener to capture UI breadcrumbs
 * @param eventName the event name (e.g. "click")
 * @returns wrapped breadcrumb events handler
 * @hidden
 */
export function breadcrumbEventHandler(eventName, debounce) {
  if (debounce === void 0) {
    debounce = false;
  }
  return function (event) {
    // reset keypress timeout; e.g. triggering a 'click' after
    // a 'keypress' will reset the keypress debounce so that a new
    // set of keypresses can be recorded
    keypressTimeout = undefined;
    // It's possible this handler might trigger multiple times for the same
    // event (e.g. event propagation through node ancestors). Ignore if we've
    // already captured the event.
    if (!event || lastCapturedEvent === event) {
      return;
    }
    lastCapturedEvent = event;
    var captureBreadcrumb = function () {
      // try/catch both:
      // - accessing event.target (see getsentry/raven-js#838, #768)
      // - `htmlTreeAsString` because it's complex, and just accessing the DOM incorrectly
      //   can throw an exception in some circumstances.
      var target;
      try {
        target = event.target ? _htmlTreeAsString(event.target) : _htmlTreeAsString(event);
      } catch (e) {
        target = '<unknown>';
      }
      if (target.length === 0) {
        return;
      }
      getCurrentHub().addBreadcrumb({
        category: "ui." + eventName,
        message: target
      }, {
        event: event,
        name: eventName
      });
    };
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if (debounce) {
      debounceTimer = setTimeout(captureBreadcrumb);
    } else {
      captureBreadcrumb();
    }
  };
}
/**
 * Wraps addEventListener to capture keypress UI events
 * @returns wrapped keypress events handler
 * @hidden
 */
export function keypressEventHandler() {
  // TODO: if somehow user switches keypress target before
  //       debounce timeout is triggered, we will only capture
  //       a single breadcrumb from the FIRST target (acceptable?)
  return function (event) {
    var target;
    try {
      target = event.target;
    } catch (e) {
      // just accessing event properties can throw an exception in some rare circumstances
      // see: https://github.com/getsentry/raven-js/issues/838
      return;
    }
    var tagName = target && target.tagName;
    // only consider keypress events on actual input elements
    // this will disregard keypresses targeting body (e.g. tabbing
    // through elements, hotkeys, etc)
    if (!tagName || tagName !== 'INPUT' && tagName !== 'TEXTAREA' && !target.isContentEditable) {
      return;
    }
    // record first keypress in a series, but ignore subsequent
    // keypresses until debounce clears
    if (!keypressTimeout) {
      breadcrumbEventHandler('input')(event);
    }
    clearTimeout(keypressTimeout);
    keypressTimeout = setTimeout(function () {
      keypressTimeout = undefined;
    }, debounceDuration);
  };
}
/**
 * Given a child DOM element, returns a query-selector statement describing that
 * and its ancestors
 * e.g. [HTMLElement] => body > div > input#foo.btn[name=baz]
 * @returns generated DOM path
 */
function _htmlTreeAsString(elem) {
  var currentElem = elem;
  var MAX_TRAVERSE_HEIGHT = 5;
  var MAX_OUTPUT_LEN = 80;
  var out = [];
  var height = 0;
  var len = 0;
  var separator = ' > ';
  var sepLength = separator.length;
  var nextStr;
  while (currentElem && height++ < MAX_TRAVERSE_HEIGHT) {
    nextStr = _htmlElementAsString(currentElem);
    // bail out if
    // - nextStr is the 'html' element
    // - the length of the string that would be created exceeds MAX_OUTPUT_LEN
    //   (ignore this limit if we are on the first iteration)
    if (nextStr === 'html' || height > 1 && len + out.length * sepLength + nextStr.length >= MAX_OUTPUT_LEN) {
      break;
    }
    out.push(nextStr);
    len += nextStr.length;
    currentElem = currentElem.parentNode;
  }
  return out.reverse().join(separator);
}
/**
 * Returns a simple, query-selector representation of a DOM element
 * e.g. [HTMLElement] => input#foo.btn[name=baz]
 * @returns generated DOM path
 */
function _htmlElementAsString(elem) {
  var out = [];
  var className;
  var classes;
  var key;
  var attr;
  var i;
  if (!elem || !elem.tagName) {
    return '';
  }
  out.push(elem.tagName.toLowerCase());
  if (elem.id) {
    out.push("#" + elem.id);
  }
  className = elem.className;
  if (className && isString(className)) {
    classes = className.split(/\s+/);
    for (i = 0; i < classes.length; i++) {
      out.push("." + classes[i]);
    }
  }
  var attrWhitelist = ['type', 'name', 'title', 'alt'];
  for (i = 0; i < attrWhitelist.length; i++) {
    key = attrWhitelist[i];
    attr = elem.getAttribute(key);
    if (attr) {
      out.push("[" + key + "=\"" + attr + "\"]");
    }
  }
  return out.join('');
}
