import { Component } from '@angular/core';
import { MenuController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { authActions, IAuthState } from '@app/store/auth';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss']
})
export class MenuComponent {

    constructor(private menu: MenuController, public navCtrl: NavController,
                private _store: Store<IAuthState>) { }

    public onClickMenuItem = (name: string) => {
        if (name === 'log-out') {
            this._store.dispatch(authActions.logOut({automatic: false}));
            return
        }
        if (name) this.navCtrl.navigateRoot('/' + name);
    };

    public openURL() {
        const urlBackOffice: string = environment.backOfficeUrl;
        window.open(urlBackOffice, '_system');
    }
}
