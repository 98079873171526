import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPensState } from '@app/store/pens/pens.state';

export const pensState = createFeatureSelector<IPensState>('pens');

export const selectPensState = createSelector(
    pensState,
    (state: IPensState) => state
);

export const getPens = createSelector(
    pensState,
    (state: IPensState) => state.pens
);
