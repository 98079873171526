import { IPlatformTypes } from '@app/store/platform/platform.state'
import { createAction, props } from '@ngrx/store'

// The Ionic platform is ready, native features are available
export const platformReady = createAction('[Platform] Platform ready')

// App is done initializing, all services available
export const appReady = createAction('[Platform] App ready')

// Detect what platform we operate on
export const setPlatform = createAction('[Platform] Set platform', props<{platformTypes: IPlatformTypes}>())

// Detect a network change
export const setNetwork = createAction('[Platform] Set network', props<{isConnected: boolean, connectionType: string}>())

// App is paused (focus changed to another app)
export const pause = createAction('[Platform] Pause')

// App is resumed (focus returned to the app)
export const resume = createAction('[Platform] Resume')
