export interface IPlatformTypes {
    isCordovaPlatform: boolean,
    isAndroidPlatform: boolean,
    isIosPlatform: boolean,
}

export interface IPlatformState {
    isCordovaPlatform: boolean
    isAndroidPlatform: boolean
    isIosPlatform: boolean
    isLocked: boolean
    isConnected: boolean
    connectionType: string,
}

export const platformInitialState: IPlatformState = {
    isCordovaPlatform: false,
    isAndroidPlatform: false,
    isIosPlatform: false,
    isLocked: false,
    isConnected: false,
    connectionType: ''
}
