import { isRegExp } from './is';
/**
 * Truncates given string to the maximum characters count
 *
 * @param str An object that contains serializable values
 * @param max Maximum number of characters in truncated string
 * @returns string Encoded
 */
export function truncate(str, max) {
  if (max === void 0) {
    max = 0;
  }
  // tslint:disable-next-line:strict-type-predicates
  if (typeof str !== 'string' || max === 0) {
    return str;
  }
  return str.length <= max ? str : str.substr(0, max) + "...";
}
/**
 * This is basically just `trim_line` from
 * https://github.com/getsentry/sentry/blob/master/src/sentry/lang/javascript/processor.py#L67
 *
 * @param str An object that contains serializable values
 * @param max Maximum number of characters in truncated string
 * @returns string Encoded
 */
export function snipLine(line, colno) {
  var newLine = line;
  var ll = newLine.length;
  if (ll <= 150) {
    return newLine;
  }
  if (colno > ll) {
    colno = ll; // tslint:disable-line:no-parameter-reassignment
  }
  var start = Math.max(colno - 60, 0);
  if (start < 5) {
    start = 0;
  }
  var end = Math.min(start + 140, ll);
  if (end > ll - 5) {
    end = ll;
  }
  if (end === ll) {
    start = Math.max(end - 140, 0);
  }
  newLine = newLine.slice(start, end);
  if (start > 0) {
    newLine = "'{snip} " + newLine;
  }
  if (end < ll) {
    newLine += ' {snip}';
  }
  return newLine;
}
/**
 * Join values in array
 * @param input array of values to be joined together
 * @param delimiter string to be placed in-between values
 * @returns Joined values
 */
export function safeJoin(input, delimiter) {
  if (!Array.isArray(input)) {
    return '';
  }
  var output = [];
  // tslint:disable-next-line:prefer-for-of
  for (var i = 0; i < input.length; i++) {
    var value = input[i];
    try {
      output.push(String(value));
    } catch (e) {
      output.push('[value cannot be serialized]');
    }
  }
  return output.join(delimiter);
}
/** Merges provided array of keys into */
export function keysToEventMessage(keys, maxLength) {
  if (maxLength === void 0) {
    maxLength = 40;
  }
  if (!keys.length) {
    return '[object has no keys]';
  }
  if (keys[0].length >= maxLength) {
    return truncate(keys[0], maxLength);
  }
  for (var includedKeys = keys.length; includedKeys > 0; includedKeys--) {
    var serialized = keys.slice(0, includedKeys).join(', ');
    if (serialized.length > maxLength) {
      continue;
    }
    if (includedKeys === keys.length) {
      return serialized;
    }
    return truncate(serialized, maxLength);
  }
  return '';
}
/**
 * Checks if the value matches a regex or includes the string
 * @param value The string value to be checked against
 * @param pattern Either a regex or a string that must be contained in value
 */
export function isMatchingPattern(value, pattern) {
  if (isRegExp(pattern)) {
    return pattern.test(value);
  }
  if (typeof pattern === 'string') {
    return value.includes(pattern);
  }
  return false;
}
