/** The status of an event. */
export var Status;
(function (Status) {
  /** The status could not be determined. */
  Status["Unknown"] = "unknown";
  /** The event was skipped due to configuration or callbacks. */
  Status["Skipped"] = "skipped";
  /** The event was sent to Sentry successfully. */
  Status["Success"] = "success";
  /** The client is currently rate limited and will try again later. */
  Status["RateLimit"] = "rate_limit";
  /** The event could not be processed. */
  Status["Invalid"] = "invalid";
  /** A server-side error ocurred during submission. */
  Status["Failed"] = "failed";
})(Status || (Status = {}));
// tslint:disable:completed-docs
// tslint:disable:no-unnecessary-qualifier no-namespace
(function (Status) {
  /**
   * Converts a HTTP status code into a {@link Status}.
   *
   * @param code The HTTP response status code.
   * @returns The send status or {@link Status.Unknown}.
   */
  function fromHttpCode(code) {
    if (code >= 200 && code < 300) {
      return Status.Success;
    }
    if (code === 429) {
      return Status.RateLimit;
    }
    if (code >= 400 && code < 500) {
      return Status.Invalid;
    }
    if (code >= 500) {
      return Status.Failed;
    }
    return Status.Unknown;
  }
  Status.fromHttpCode = fromHttpCode;
})(Status || (Status = {}));
