<ion-menu contentId="main-content" side="start" persistent="true">
    <ion-header>
        <ion-toolbar >
            <ion-title color="light">Menu</ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <ion-list>
            <ion-menu-toggle auto-hide="false">
                <ion-item (click)="onClickMenuItem('dashboard')">
                    <ion-icon md="home" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.HOME' | transloco}}</ion-label>
                </ion-item>
                <ion-item (click)="onClickMenuItem('recorded-test')">
                    <ion-icon md="document" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.RECORDED_TESTS' | transloco}}</ion-label>
                </ion-item>

                <ion-item (click)="onClickMenuItem('download-animals')">
                    <ion-icon md="download" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.DOWNLOAD_ANIMALS' | transloco}}</ion-label>
                </ion-item>

                <ion-item (click)="onClickMenuItem('test-settings')">
                    <ion-icon md="toggle-outline" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.TEST_SETTINGS' | transloco}}</ion-label>
                </ion-item>

                <ion-item (click)="onClickMenuItem('settings')">
                    <ion-icon md="settings" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.SETTINGS' | transloco}}</ion-label>
                </ion-item>

                <ion-item (click)="onClickMenuItem('log-out')">
                    <ion-icon class="footer-icon" md="log-out" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.LOGOUT' | transloco}}</ion-label>
                </ion-item>

                <ion-item (click)="openURL()">
                    <ion-icon class="" md="business" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.BACK_OFFICE' | transloco}}</ion-label>
                </ion-item>

                <ion-item>
                    <ion-icon class="" md="build" slot="start"></ion-icon>
                    <ion-label>{{ 'MENU.VERSION' | transloco}}: {{ 'GENERAL.VERSION' | transloco}}</ion-label>
                </ion-item>
            </ion-menu-toggle>
        </ion-list>
    </ion-content>
</ion-menu>
