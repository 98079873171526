import { Status } from '@sentry/types';
/** Noop transport */
var NoopTransport = /** @class */function () {
  function NoopTransport() {}
  /**
   * @inheritDoc
   */
  NoopTransport.prototype.sendEvent = function (_) {
    return Promise.resolve({
      reason: "NoopTransport: Event has been skipped because no Dsn is configured.",
      status: Status.Skipped
    });
  };
  /**
   * @inheritDoc
   */
  NoopTransport.prototype.close = function (_) {
    return Promise.resolve(true);
  };
  return NoopTransport;
}();
export { NoopTransport };
