import { InjectionToken } from '@angular/core'

import { ActionReducerMap } from '@ngrx/store'
import { IPlatformState, PlatformReducer } from '@app/store/platform'
import { IAuthState, AuthReducer } from '@app/store/auth';
import { ITestsState, TestsReducer } from '@app/store/tests';
import { ITraitState, TraitsReducer } from '@app/store/traits';
import { IPensState, PensReducer } from '@app/store/pens';
import { AnimalsReducer, IAnimalsState } from '@app/store/animals';

export interface AppState {
    auth: IAuthState,
    tests: ITestsState,
    pens: IPensState,
    traits: ITraitState,
    animals: IAnimalsState,
    platform: IPlatformState,
}

export const reducers: ActionReducerMap<AppState> = {
    auth: AuthReducer,
    tests: TestsReducer,
    pens: PensReducer,
    traits: TraitsReducer,
    animals: AnimalsReducer,
    platform: PlatformReducer,
}

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers')

export const reducerProvider = [
    { provide: reducerToken, useValue: reducers }
]
