/** Console logging verbosity for the SDK. */
export var LogLevel;
(function (LogLevel) {
  /** No logs will be generated. */
  LogLevel[LogLevel["None"] = 0] = "None";
  /** Only SDK internal errors will be logged. */
  LogLevel[LogLevel["Error"] = 1] = "Error";
  /** Information useful for debugging the SDK will be logged. */
  LogLevel[LogLevel["Debug"] = 2] = "Debug";
  /** All SDK actions will be logged. */
  LogLevel[LogLevel["Verbose"] = 3] = "Verbose";
})(LogLevel || (LogLevel = {}));
