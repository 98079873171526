import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { AppInitService } from '@app/services/app-init/app-init.service';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from '@app/services/auth/auth.service';
import { addIcons } from 'ionicons';
import { calendar, eyeOff, business, logOut, toggleOutline, download, document, home, build, settings, calendarOutline, checkmarkCircle,
    radioButtonOff, trashOutline, arrowForward, arrowBackOutline, trash, arrowBack, settingsOutline, informationCircle, close, addCircleOutline, removeCircleOutline, chevronUpOutline, chevronDownOutline } from 'ionicons/icons';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {

    constructor(private platform: Platform,
                private splashScreen: SplashScreen,
                private _translateService: TranslocoService,
                private statusBar: StatusBar, private authService: AuthService,
                private appInitService: AppInitService) {
        addIcons({ calendar, eyeOff, business, logOut, toggleOutline, download, document, home, build, settings,
            calendarOutline, checkmarkCircle, radioButtonOff, trashOutline, arrowForward, arrowBackOutline, trash,
            arrowBack, settingsOutline, informationCircle, close, addCircleOutline, removeCircleOutline, chevronUpOutline, chevronDownOutline });
    }

    ngOnInit() {
        this.appInitService.init();
        void this.setLanguage();

        this.platform.ready().then(() => {
            setTimeout(() => {
                this.splashScreen.hide();
            }, 1000);

            if (this.platform.is('android')) {
                this.statusBar.overlaysWebView(false);
                this.statusBar.backgroundColorByHexString(environment.toolbarColor);
            } else {
                this.statusBar.styleDefault();
            }
        });
    }

    public async setLanguage() {
        const language = await this.authService.getUserLanguage();
        // Set the default language
        // this._translateService.setDefaultLang('ENG');
        if (language && this._translateService.getAvailableLangs().indexOf(language) > 0) {
            this._translateService.setActiveLang(language);
        } else {
            // Use a language
            const newLan = await this.authService.setUserLanguage('en');
            this._translateService.setActiveLang('en');
            this.authService.setUserLanguage('en')
        }
    }
}
