import { createReducer, on } from '@ngrx/store';
import { initialPensState } from '@app/store/pens/pens.state';
import { pensActions } from '@app/store/pens';
import { authActions } from '@app/store/auth';

export const PensReducer = createReducer(
    initialPensState,

    on(pensActions.getPens, (state) => {
        return ({ ...state, isLoading: true, hasError: false, errorMessage: null, tests: null });
    }),

    on(pensActions.setPens, (state, { pens }) => {
        return ({ ...state, pens, isLoading: false });
    }),

    on(pensActions.setLocalPens, (state, { pens }) => {
        return ({ ...state, pens, isLoading: false });
    }),

    on(pensActions.setTestsError, (state, { errorMessage }) => {
        return ({ ...state, hasError: true, errorMessage, isLoading: false });
    }),

    on(authActions.logOut, (state, action) => {
        return initialPensState;
    }),
);

export function reducer(state, action) {
    return PensReducer(state, action);
}
