export interface IPensState {
    pens: any[],
    isLoading: boolean;
    hasError: boolean;
    errorMessage: string;
}

export const initialPensState: IPensState = {
    pens: null,
    isLoading: false,
    hasError: false,
    errorMessage: null
};
