import { urlEncode } from '@sentry/utils';
import { Dsn } from './dsn';
var SENTRY_API_VERSION = '7';
/** Helper class to provide urls to different Sentry endpoints. */
var API = /** @class */function () {
  /** Create a new instance of API */
  function API(dsn) {
    this.dsn = dsn;
    this._dsnObject = new Dsn(dsn);
  }
  /** Returns the Dsn object. */
  API.prototype.getDsn = function () {
    return this._dsnObject;
  };
  /** Returns a string with auth headers in the url to the store endpoint. */
  API.prototype.getStoreEndpoint = function () {
    return "" + this._getBaseUrl() + this.getStoreEndpointPath();
  };
  /** Returns the store endpoint with auth added in url encoded. */
  API.prototype.getStoreEndpointWithUrlEncodedAuth = function () {
    var dsn = this._dsnObject;
    var auth = {
      sentry_key: dsn.user,
      sentry_version: SENTRY_API_VERSION
    };
    // Auth is intentionally sent as part of query string (NOT as custom HTTP header)
    // to avoid preflight CORS requests
    return this.getStoreEndpoint() + "?" + urlEncode(auth);
  };
  /** Returns the base path of the url including the port. */
  API.prototype._getBaseUrl = function () {
    var dsn = this._dsnObject;
    var protocol = dsn.protocol ? dsn.protocol + ":" : '';
    var port = dsn.port ? ":" + dsn.port : '';
    return protocol + "//" + dsn.host + port;
  };
  /** Returns only the path component for the store endpoint. */
  API.prototype.getStoreEndpointPath = function () {
    var dsn = this._dsnObject;
    return (dsn.path ? "/" + dsn.path : '') + "/api/" + dsn.projectId + "/store/";
  };
  /** Returns an object that can be used in request headers. */
  API.prototype.getRequestHeaders = function (clientName, clientVersion) {
    var dsn = this._dsnObject;
    var header = ["Sentry sentry_version=" + SENTRY_API_VERSION];
    header.push("sentry_timestamp=" + new Date().getTime());
    header.push("sentry_client=" + clientName + "/" + clientVersion);
    header.push("sentry_key=" + dsn.user);
    if (dsn.pass) {
      header.push("sentry_secret=" + dsn.pass);
    }
    return {
      'Content-Type': 'application/json',
      'X-Sentry-Auth': header.join(', ')
    };
  };
  /** Returns the url to the report dialog endpoint. */
  API.prototype.getReportDialogEndpoint = function (dialogOptions) {
    if (dialogOptions === void 0) {
      dialogOptions = {};
    }
    var dsn = this._dsnObject;
    var endpoint = "" + this._getBaseUrl() + (dsn.path ? "/" + dsn.path : '') + "/api/embed/error-page/";
    var encodedOptions = [];
    encodedOptions.push("dsn=" + dsn.toString());
    for (var key in dialogOptions) {
      if (key === 'user') {
        if (!dialogOptions.user) {
          continue;
        }
        if (dialogOptions.user.name) {
          encodedOptions.push("name=" + encodeURIComponent(dialogOptions.user.name));
        }
        if (dialogOptions.user.email) {
          encodedOptions.push("email=" + encodeURIComponent(dialogOptions.user.email));
        }
      } else {
        encodedOptions.push(encodeURIComponent(key) + "=" + encodeURIComponent(dialogOptions[key]));
      }
    }
    if (encodedOptions.length) {
      return endpoint + "?" + encodedOptions.join('&');
    }
    return endpoint;
  };
  return API;
}();
export { API };
