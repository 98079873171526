import * as tslib_1 from "tslib";
/**
 * Consumes the promise and logs the error when it rejects.
 * @param promise A promise to forget.
 */
export function forget(promise) {
  promise.catch(function (e) {
    // TODO: Use a better logging mechanism
    console.error(e);
  });
}
/**
 * Helper to filter an array with asynchronous callbacks.
 *
 * @param array An array containing items to filter.
 * @param predicate An async predicate evaluated on every item.
 * @param thisArg Optional value passed as "this" into the callback.
 * @returns An array containing only values where the callback returned true.
 */
export function filterAsync(array, predicate, thisArg) {
  return tslib_1.__awaiter(this, void 0, void 0, function () {
    var verdicts;
    return tslib_1.__generator(this, function (_a) {
      switch (_a.label) {
        case 0:
          return [4 /*yield*/, Promise.all(array.map(predicate, thisArg))];
        case 1:
          verdicts = _a.sent();
          return [2 /*return*/, array.filter(function (_, index) {
            return verdicts[index];
          })];
      }
    });
  });
}
