import { __decorate, __extends } from "tslib";
import { Injectable } from '@angular/core';
import { AwesomeCordovaNativePlugin, cordova } from '@awesome-cordova-plugins/core';
import * as i0 from "@angular/core";
var SplashScreen = /** @class */function (_super) {
  __extends(SplashScreen, _super);
  function SplashScreen() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  SplashScreen.prototype.show = function () {
    return cordova(this, "show", {
      "sync": true
    }, arguments);
  };
  SplashScreen.prototype.hide = function () {
    return cordova(this, "hide", {
      "sync": true
    }, arguments);
  };
  SplashScreen.ɵfac = /* @__PURE__ */(() => {
    let ɵSplashScreen_BaseFactory;
    return function SplashScreen_Factory(__ngFactoryType__) {
      return (ɵSplashScreen_BaseFactory || (ɵSplashScreen_BaseFactory = i0.ɵɵgetInheritedFactory(SplashScreen)))(__ngFactoryType__ || SplashScreen);
    };
  })();
  SplashScreen.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: SplashScreen,
    factory: SplashScreen.ɵfac
  });
  SplashScreen.pluginName = "SplashScreen";
  SplashScreen.plugin = "cordova-plugin-splashscreen";
  SplashScreen.pluginRef = "navigator.splashscreen";
  SplashScreen.repo = "https://github.com/apache/cordova-plugin-splashscreen";
  SplashScreen.platforms = ["Amazon Fire OS", "Android", "iOS", "Windows"];
  SplashScreen = __decorate([], SplashScreen);
  return SplashScreen;
}(AwesomeCordovaNativePlugin);
export { SplashScreen };
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplashScreen, [{
    type: Injectable
  }], null, {
    show: [],
    hide: []
  });
})();
