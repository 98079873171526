import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { AuthService } from '@app/services/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { authActions } from '@app/store/auth';

@Injectable()
export class RefreshSessionInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private router: Router, protected _http: HttpClient, private authService: AuthService, private _store: Store<AppState>) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.status === 200) {
                        const success = evt.body.success;
                        if (success === 'false' && evt.body && evt.body.session_id === '0'){
                            return
                        }
                        if (success === 'false') {
                            this.logoutUser();
                        }
                    } else if (evt.status === 403) { // Forbidden
                        this.logoutUser();
                    }
                }
            }),
            catchError((err: any) => {
                console.log('ERR', err);
                if (err instanceof HttpErrorResponse) {
                    if (!window.navigator.onLine) {
                        // if there is no internet, throw a HttpErrorResponse error
                        // since an error is thrown, the function will terminate here
                        console.log('NO INTERNET');
                        return throwError(new HttpErrorResponse({ error: 'No internet' }));
                    }
                    if (err.status === 0 && err.error && err.error.type === 'error') {
                        return throwError(new HttpErrorResponse({ error: 'No internet' }));
                    }
                    if (err.status === 400 && err.error) { // Forbidden

                        if (!err.error.success && err.error.message === 'Please login to access this resource') {
                            this.logoutUser();
                        }
                        if (!err.error.success && err.error.message === 'Refresh Token Is Not Valid.') {
                            this.logoutUser();
                        }
                    }
                }
                return of(err);
            }));
    }

    private logoutUser() {
        this._store.dispatch(authActions.logOut({automatic: true}));
        return throwError({});
    }

}
