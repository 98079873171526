Object.defineProperty(exports, "__esModule", {
  value: true
});
var tslib_1 = require("tslib");
var types_1 = require("@sentry/types");
var utils_1 = require("@sentry/utils");
var base_1 = require("./base");
var global = utils_1.getGlobalObject();
/** `fetch` based transport */
var FetchTransport = /** @class */function (_super) {
  tslib_1.__extends(FetchTransport, _super);
  function FetchTransport() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  /**
   * @inheritDoc
   */
  FetchTransport.prototype.sendEvent = function (event) {
    var defaultOptions = {
      body: JSON.stringify(event),
      method: 'POST',
      // Despite all stars in the sky saying that Edge supports old draft syntax, aka 'never', 'always', 'origin' and 'default
      // https://caniuse.com/#feat=referrer-policy
      // It doesn't. And it throw exception instead of ignoring this parameter...
      // REF: https://github.com/getsentry/raven-js/issues/1233
      referrerPolicy: utils_1.supportsReferrerPolicy() ? 'origin' : ''
    };
    return this._buffer.add(global.fetch(this.url, defaultOptions).then(function (response) {
      return {
        status: types_1.Status.fromHttpCode(response.status)
      };
    }));
  };
  return FetchTransport;
}(base_1.BaseTransport);
exports.FetchTransport = FetchTransport;
