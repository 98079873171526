import * as tslib_1 from "tslib";
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/core';
import { getGlobalObject } from '@sentry/utils';
var global = getGlobalObject();
/** UserAgent */
var UserAgent = /** @class */function () {
  function UserAgent() {
    /**
     * @inheritDoc
     */
    this.name = UserAgent.id;
  }
  /**
   * @inheritDoc
   */
  UserAgent.prototype.setupOnce = function () {
    addGlobalEventProcessor(function (event) {
      if (getCurrentHub().getIntegration(UserAgent)) {
        if (!global.navigator || !global.location) {
          return event;
        }
        // HTTP Interface: https://docs.sentry.io/clientdev/interfaces/http/?platform=javascript
        var request = event.request || {};
        request.url = request.url || global.location.href;
        request.headers = request.headers || {};
        request.headers['User-Agent'] = global.navigator.userAgent;
        return tslib_1.__assign({}, event, {
          request: request
        });
      }
      return event;
    });
  };
  /**
   * @inheritDoc
   */
  UserAgent.id = 'UserAgent';
  return UserAgent;
}();
export { UserAgent };
