import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as Sentry from 'sentry-cordova';
import { AuthService } from '@app/services/auth/auth.service';

@Injectable()
export class SentryIonicErrorHandler extends ErrorHandler {
    private userFullName;

    constructor(protected injector: Injector, private authService: AuthService) {
        super();
        this.getUser();
    }

    private async getUser() {
        const userId = await this.authService.getLoggedUser();
        let user = null;
        if (userId) {
            user = await this.authService.getUser(userId);
            this.userFullName = user.username;
        }
    }

    handleError(error) {
        super.handleError(error);
        try {
            // 	scope.setLevel('fatal');
            // 	scope.setLevel('error');
            // 	scope.setLevel('warning');
            // 	scope.setLevel('info');

            Sentry.configureScope(scope => {
                scope.setUser({
                    username: this.userFullName
                });
            });

            console.log('[SentryIonicErrorHandler.handleError]', error.originalError);
            Sentry.captureException(error.originalError || error);
        } catch (e) {
            console.error(e);
        }
    }
}
